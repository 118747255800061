import { mapState, mapGetters, mapMutations } from "vuex";
import store from '@/store';
export default {
  name: "welcomePage",
  components: {},
  data: function data() {
    return {
      bgIMg: require('../../assets/img/welcome/welcome.png')
    };
  },
  computed: {},
  created: function created() {},
  methods: {}
};