var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "auto",
      height: "calc(100vh - 140px)",
      "border-radius": "10px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("img", {
    staticClass: "bg_img",
    attrs: {
      src: _vm.bgIMg,
      alt: ""
    }
  }), _c("span", [_vm._v(_vm._s(_vm.$t("welcome.welcomeTo")) + " KNOWHERE " + _vm._s(_vm.$t("common.appName")))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };